import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/phone-auth/phone-auth-page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/auth/components/phone-auth/phone-auth.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/layout/footer/footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/header/header.tsx");
